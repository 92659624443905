<template>
  <div class="filter-date" :class="{ active: editing }">
    <div v-if="editing">
      <v-popover
        ref="popover"
        popoverClass="popover-date"
        :placement="placement"
        offset="10px"
        :handleResize="true"
      >
        <template slot="popover">
          <div class="date">
            <b-form-group :label="keyLabel + ' :'">
              <b-form-select v-model="selectedFilter">
                <b-form-select-option value=""></b-form-select-option>
                <b-form-select-option value="=">Égale à</b-form-select-option>
                <b-form-select-option value="<>"
                  >Pas Égale à</b-form-select-option
                >
                <b-form-select-option value=">"
                  >Supérieur à</b-form-select-option
                >
                <b-form-select-option value="<"
                  >Inférieur à</b-form-select-option
                >
                <b-form-select-option value=">="
                  >Supérieur ou égale à</b-form-select-option
                >
                <b-form-select-option value="<="
                  >Inférieur ou égale à</b-form-select-option
                >
                <b-form-select-option value="8"
                  >Valeur comprise</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="selectedFilter && selectedFilter != 8">
              <date-picker
                v-model="selectedDate"
                type="date"
                placeholder="Sélectionner la date"
                :append-to-body="false"
              ></date-picker>
            </b-form-group>
            <div v-if="selectedFilter == 8">
              <b-form-group label="Enter">
                <date-picker
                  v-model="startDate"
                  type="date"
                  placeholder="Sélectionner la date"
                  :append-to-body="false"
                ></date-picker>
              </b-form-group>
              <b-form-group label="Et">
                <date-picker
                  v-model="endDate"
                  type="date"
                  placeholder="Sélectionner la date"
                  :append-to-body="false"
                ></date-picker>
              </b-form-group>
            </div>
          </div>
          <div class="action">
            <b-button
              variant="outline-secondary"
              size="sm"
              @click="hundlePopoverCancel()"
            >
              Annuler
            </b-button>
            <b-button variant="success" size="sm" @click="hundlePopoverOk()">
              Enregistrer
            </b-button>
          </div>
        </template>
      </v-popover>
    </div>
    <div
      @click.prevent.stop="editing = true"
      class="icon"
      :class="{
        'filter-close': keyLabel == 'Closé le',
        'filter-date_debut': keyLabel == 'Date de debut',
        iconrefactor: styleFilterHiddenCol
      }"
      @click="ouvert"
    >
      <font-awesome-icon class="filter-icon" :icon="['fas', 'filter']" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
import * as moment from 'moment'

Vue.component('v-popover', VPopover)
export default {
  props: {
    placement: {
      type: String,
      default: 'auto'
    },
    keyName: {
      type: String,
      default: ''
    },
    keyLabel: {
      type: String,
      default: ''
    },
    styleFilterHiddenCol: { required: false }
  },
  data() {
    return {
      selectedFilter: null,
      selectedDate: null,
      startDate: null,
      endDate: null,
      editing: false
    }
  },
  methods: {
    hundlePopoverCancel() {
      this.$refs['popover'].hide()
      this.reset()
      this.editing = false
    },
    reset() {
      this.selectedFilter = null
      this.selectedDate = null
      this.startDate = null
      this.endDate = null
    },
    async hundlePopoverOk() {
      const payload = []
      if (this.selectedFilter) {
        if (this.selectedFilter == 8 && this.startDate && this.endDate) {
          if (this.keyName == 'date_debut_formation') {
            payload.push({
              glue: 'and',
              key: this.keyName,
              operateur: this.selectedFilter,
              keyLabel: this.keyLabel,
              value: [
                moment(this.startDate).format('DD-MM-YYYY'),
                moment(this.endDate).format('DD-MM-YYYY')
              ],
              valueLabel:
                this.$options.filters.formatDate(this.selectedFilter) +
                '  ' +
                `entre
              ${moment(this.startDate).format('DD/MM/YYYY')} et
              ${moment(this.endDate).format('DD/MM/YYYY')}
            `
            })
          } else {
            payload.push({
              glue: 'and',
              key: this.keyName,
              operateur: this.selectedFilter,
              keyLabel: this.keyLabel,
              value: [
                moment(this.startDate).format('YYYY-MM-DD'),
                moment(this.endDate).format('YYYY-MM-DD')
              ],
              valueLabel:
                this.$options.filters.formatDate(this.selectedFilter) +
                '  ' +
                `entre
              ${moment(this.startDate).format('DD/MM/YYYY')} et
              ${moment(this.endDate).format('DD/MM/YYYY')}
            `
            })
          }
        } else if (this.selectedDate) {
          if (this.keyName == 'date_debut_formation') {
            payload.push({
              glue: 'and',
              key: this.keyName,
              operateur: this.selectedFilter,
              keyLabel: this.keyLabel,
              value: moment(this.selectedDate).format('DD-MM-YYYY'),
              valueLabel:
                this.$options.filters.formatDate(this.selectedFilter) +
                '  ' +
                moment(this.selectedDate).format('DD/MM/YYYY')
            })
          } else {
            payload.push({
              glue: 'and',
              key: this.keyName,
              operateur: this.selectedFilter,
              keyLabel: this.keyLabel,
              value: moment(this.selectedDate).format('YYYY-MM-DD'),
              valueLabel:
                this.$options.filters.formatDate(this.selectedFilter) +
                '  ' +
                moment(this.selectedDate).format('DD/MM/YYYY')
            })
          }
        }
      } else {
        payload.push({
          key: this.keyName,
          value: null,
          operateur: '='
        })
      }
      this.$emit('change', payload)
      this.hundlePopoverCancel()
    },
    ouvert() {
      this.$nextTick(() => {
        this.$refs['popover'].show()
      })
    }
  },
  watch: {
    editing(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs['popover'].show()
        })
      }
    }
  },
  filters: {
    formatDate: value => {
      switch (value) {
        case '=':
          return 'Égale à'
        case '<>':
          return 'Pas Égale à'
        case '>':
          return 'Supérieur à'
        case '<':
          return 'Inférieur à'
        case '>=':
          return 'Supérieur ou égale à'
        case '<=':
          return 'Inférieur ou égale à'
        case '8':
          return 'Valeur comprise'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-date {
    opacity: 1;
    display: block !important;
    z-index: 2000;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 20px 0 0;
      text-align: left;
      max-width: unset;
      width: 300px;

      .close-tooltip {
        position: absolute;
        top: 3px;
        right: 8px;
        color: #656565;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .date {
        .form-group {
          margin: 0 16px 10px;
        }
      }
      .action {
        margin: 0;
        padding: 8px 16px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #f7f7f7;
        button {
          &:first-child {
            margin-right: 4px;
          }
        }
      }
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;

      border-color: black !important;
      background: white !important;
      border-width: 1px !important;
      border-left-color: rgba(0, 0, 0, 0.2) !important;
      border-right-color: rgba(0, 0, 0, 0.2) !important;
      border-top-color: rgba(0, 0, 0, 0.2) !important;
      border-bottom-color: rgba(0, 0, 0, 0.2) !important;

      width: 10px;
      height: 10px;
      z-index: -2;
      transform: rotateZ(45deg);
    }

    &[x-placement^='top'] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;

      .tooltip-arrow {
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='right'] {
      margin-left: 52px;
      .tooltip-arrow {
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^='left'] {
      margin-right: 5px;

      .tooltip-arrow {
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;
      max-width: unset;
      border-radius: 0.1rem;
      .popover-inner {
        background: $color;
        color: black;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
  .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.5;
  }
  .d-block {
    display: block !important;
    font-size: 12px;
  }
  .form-control {
    font-size: 12px;
  }
}
.filter-date {
  &.active {
    margin: -9px;
  }
  .icon {
    width: 16px;
    // border: 1px solid #d8d8d8;
    position: absolute;
    left: 76%;
    right: 0%;
    bottom: 15px;
    top: 23px;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      background-color: transparent;
    }
    &.iconrefactor {
      top: 15px;
    }
    .filter-icon {
      color: #c5c5c5;
      font-size: 13px;
      &:hover {
        background-color: transparent;
        color: #5e5e5e;
      }
    }
    &.filter-close {
      width: 16px;
      // border: 1px solid #d8d8d8;
      position: absolute;
      left: 81%;
      right: 0%;
      bottom: 15px;
      top: 15px;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        background-color: transparent;
      }
      &.iconrefactor {
        top: 15px;
      }
      .filter-icon {
        color: #c5c5c5;
        font-size: 13px;
        &:hover {
          background-color: transparent;
          color: #5e5e5e;
        }
      }
    }
    &.filter-date_debut {
      width: 16px;
      // border: 1px solid #d8d8d8;
      position: absolute;
      left: 85%;
      right: 0%;
      bottom: 15px;
      top: 15px;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        background-color: transparent;
      }
      &.iconrefactor {
        top: 15px;
      }
      .filter-icon {
        color: #c5c5c5;
        font-size: 13px;
        &:hover {
          background-color: transparent;
          color: #5e5e5e;
        }
      }
    }
  }
}
</style>
